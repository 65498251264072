exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-consumer-privacy-index-js": () => import("./../../../src/pages/consumer-privacy/index.js" /* webpackChunkName: "component---src-pages-consumer-privacy-index-js" */),
  "component---src-pages-dsar-index-js": () => import("./../../../src/pages/dsar/index.js" /* webpackChunkName: "component---src-pages-dsar-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-index-js": () => import("./../../../src/pages/join/index.js" /* webpackChunkName: "component---src-pages-join-index-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-purpose-index-js": () => import("./../../../src/pages/purpose/index.js" /* webpackChunkName: "component---src-pages-purpose-index-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-success-index-js": () => import("./../../../src/pages/success/index.js" /* webpackChunkName: "component---src-pages-success-index-js" */),
  "component---src-pages-waiting-list-index-js": () => import("./../../../src/pages/waiting-list/index.js" /* webpackChunkName: "component---src-pages-waiting-list-index-js" */),
  "component---src-templates-retailer-page-js": () => import("./../../../src/templates/retailer-page.js" /* webpackChunkName: "component---src-templates-retailer-page-js" */)
}

